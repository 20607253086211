<template>
  <v-container fill-height>
    <v-slide-y-transition
      mode="out-in"
      md12
    >
      <v-layout
        wrap
        align-center
        justify-center
      >
        <v-flex style="max-width: 700px; width: 100%">
          <v-card
            text
            height="100%"
            xtile
          >
            <v-flex
              cols="12"
              class="grey darken-3 ma-0 pa-6 align-center justify-center text-center"
            >
              <v-img
                :src="background"
                width="100%"
                max-height="125px"
                max-width="472px"
                style="margin: auto"
              />
            </v-flex>
            <v-card-title primary-title>
              <v-layout
                column
                justify-top
              >
                <v-flex md5>
                  <span class="headline">Login to {{title}}</span>
                  <v-progress-linear
                    v-if="processing"
                    :indeterminate="true"
                    class="my-4"
                  ></v-progress-linear>
                </v-flex>
              </v-layout>
            </v-card-title>

            <v-divider />

            <v-form
              ref='frmLogin'
              v-if="!processing"
            >

              <v-card-text class="py-0 px-4">
                <v-alert
                  :value="errorText"
                  class="mt-2"
                  color="error"
                  v-if="errorText.length > 0 "
                >
                  {{errorText}}
                </v-alert>
                <v-layout
                  column
                  justify-top
                >
                  <v-flex md5>
                    <v-text-field
                      v-model="email"
                      type="email"
                      label='Email Address'
                      :rules="[v=> !!v || 'Please enter your email address']"
                      :disabled="emailReadOnly"
                    />
                    <v-text-field
                      v-model="password"
                      label='Password'
                      :type="'password'"
                      required
                      data-vv-name='password'
                      :rules="[v=> !!v || 'Please enter your password']"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="secondary" v-if="!processing" @click.prevent="forgotPassword" text>
                  Forgot Password?
                </v-btn> -->
                <v-btn
                  v-if="!processing"
                  @click.prevent="submit"
                  text
                  color="primary"
                  type="submit"
                >Login</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import SelectFunctions from '../select/SelectFunctions';
import _ from 'lodash';
import background from '@/assets/background.png';

export default {

  mounted: function ()
  {
    console.log('Mounted login component');
    console.log('here!');


    if (this.$route.params.email)
    {
      this.email = this.$route.params.email;
      this.emailReadOnly = true;
    }

    // console.log("RandomBackground: ",this.randomBackground())

  },
  data: () => ({
    email: '',
    password: '',
    processing: false,
    _csrf: '',
    loginError: false,
    errorText: '',

    background,

    title: process.env.VUE_APP_APP_TITLE,

    emailReadOnly: false,
  }),
  methods: {
    async forgotPassword ()
    {
      this.$router.push('forgotpassword');
    },
    async submit ()
    {
      if (this.$refs.frmLogin.validate())
      {
        // form is valid, we can therefore submit to backend for login.
        this.processing = true;
        this.errorText = '';
        var promise = new Promise((resolve, reject) =>
        {
          let params =
          {
            emailaddress: this.email.toLowerCase(),
            password: this.password,
            _csrf: this._csrf
          };
          console.log(JSON.stringify(params));
          SelectFunctions.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'logon',
            {
              method: 'POST', credentials: 'include',
              headers:
              {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(params)
            })
            .then(result =>
            {
              if (result.ok)
              {
                this.processing = false;
                resolve(result);
              }
              else
              {
                this.processing = false;
                this.errorText = 'Sorry, the credentials you supplied cannot be matched';
              }
            })
            .catch(error =>
            {
              this.processing = false;
              this.errorText = 'Offline!';
              reject(error);
            });

        });
        promise.then((result) =>
        {
          //this.$router.push("/");
          window.location.href = '/';
        }, (error) =>
        {
          this.processing = false;
          if (error == '401 ERROR')
          {
            this.errorText = 'Sorry, the credentials you supplied cannot be matched';
          }
          else
          {
            this.errorText = error;
          }
        });
      }
      else
      {
        this.errorText = 'The form is not valid, please ensure that you enter all required fields.';
      }

    }
  }
};
</script>